import { useState } from 'react'
import { Preview } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomReactTable from 'components/ReactTablePagination/CustomReactTable'
import statisticsProjectInfoViewedColumns from './helpers/statistics-project-info-viewed-headers'
import { useWindowsSize } from 'shared/customHooks/useWindowsSize'

const StatisticsProjectInfoViewedScreen = () => {
	const windowsSize = useWindowsSize()

	const [data, setData] = useState([])

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<Preview />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<CustomReactTable
							data={data}
							columns={statisticsProjectInfoViewedColumns(windowsSize)}
							// fetchData={fetchData}
							// nextPage={optionsTable.nextPage}
							// prevPage={optionsTable.prevPage}
							// totalPages={optionsTable.totalPages}
						/>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default StatisticsProjectInfoViewedScreen
