import { format } from 'date-fns'

export const seeInfoColumns = () => [
	{
		header: 'Usuario',
		accessor: 'user',
	},
	{
		header: 'Fecha',
		accessor: 'createdAt',
		cell: ({ cell, row }) => <>{format(new Date(row.createdAt), 'dd/MM/yyyy- hh:mm bbbb')}</>,
	},
	{
		header: 'Proyecto',
		accessor: 'projectName',
	},
]
