import { Edit, Delete } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'

export const retentionListColumns = ({ handleOpenRegisterRetentionModal, handleOpenDeleteRetentionModal }) => [
	{
		header: 'Pais',
		accessor: 'country',
		size: 'sm',
	},
	{
		header: 'Retención',
		accessor: 'retention',
		size: 'sm',
	},
	{
		header: 'Acciones',
		accessor: 'actions',
		headerAlign: 'right',
		cellAlign: 'right',
		size: 'sm',
		cell: ({ row, cell }) => (
			<div className='actions-right'>
				<Button
					justIcon
					round
					simple
					onClick={() => handleOpenRegisterRetentionModal(row.item)}
					color='success'
					className='edit'
				>
					<Edit />
				</Button>
				<Button
					justIcon
					round
					simple
					onClick={() => handleOpenDeleteRetentionModal(row.item)}
					color='danger'
					className='delete'
				>
					<Delete />
				</Button>
			</div>
		),
	},
]
