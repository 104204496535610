import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import RetentionInputs from './RetentionInputs'
import { registerRetention, updateRetention } from 'local_redux/actions/retentionActions'
import { RETENTION_REGISTER_RESET, RETENTION_UPDATE_RESET } from 'local_redux/constants/retentionConstants'
import styles from '../styles/registerRetentionModalStyles'

const useStyles = makeStyles(styles)

const RegisterRetentionModal = ({ handleCloseRetentionModal, registerRetentionModal, showRetentionModalInfo }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const retentionInitialState = showRetentionModalInfo._id
    ? showRetentionModalInfo
    : {
        country: '',
        retention: '',
      }

  const [retentionState, setRetentionState] = useState(retentionInitialState)

  const { loadingRetentionRegister, successRetentionRegister, errorRetentionRegister } = useSelector(
    (state) => state.retentionRegister
  )
  const { loadingRetentionUpdate, successRetentionUpdate, errorRetentionUpdate } = useSelector(
    (state) => state.retentionUpdate
  )

  useEffect(() => {
    let timeOut
    if (successRetentionUpdate || successRetentionRegister) {
      timeOut = setTimeout(() => {
        handleCloseRetentionModal()
      }, 1500)
    }
  }, [successRetentionUpdate, successRetentionRegister])
  useEffect(() => {
    return () => {
      if (!showRetentionModalInfo._id) {
        dispatch({ type: RETENTION_REGISTER_RESET })
      } else {
        dispatch({ type: RETENTION_UPDATE_RESET })
      }
    }
  }, [dispatch])

  const handleRegisterRetention = (e) => {
    e.preventDefault()
    if (!showRetentionModalInfo._id) {
      return dispatch(registerRetention(retentionState))
    } else {
      return dispatch(updateRetention(retentionState))
    }
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={registerRetentionModal}
      keepMounted
      onClose={handleCloseRetentionModal}
      aria-labelledby='retention-modal-title'
      aria-describedby='retention-modal-description'
    >
      <form onSubmit={handleRegisterRetention}>
        <DialogTitle id='retention-modal-title' disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleCloseRetentionModal}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4>{showRetentionModalInfo._id ? 'Editar retención' : 'Registrar retención'}</h4>
        </DialogTitle>

        <DialogContent id='retention-modal-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <RetentionInputs retention={retentionState} setRetention={setRetentionState} />
            </GridItem>
          </GridContainer>

          {errorRetentionRegister && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorRetentionRegister} color='danger' />
              </GridItem>
            </GridContainer>
          )}
          {errorRetentionUpdate && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorRetentionUpdate} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem xs={6}>
              <Button onClick={handleCloseRetentionModal} block>
                Cancelar
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button
                type='onSubmit'
                color={successRetentionRegister || successRetentionUpdate ? 'success' : 'primary'}
                disabled={!retentionState.country || !retentionState.retention}
                block
              >
                {!showRetentionModalInfo._id
                  ? loadingRetentionRegister
                    ? 'Registrando'
                    : successRetentionRegister
                    ? 'Registrado'
                    : 'Registrar'
                  : loadingRetentionUpdate
                  ? 'Editando'
                  : successRetentionUpdate
                  ? 'Editado'
                  : 'Editar'}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RegisterRetentionModal
