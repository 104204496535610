import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import EmailChangeModal from 'components/CustomModals/EmailChangeModal/EmailChangeModal'
import TopCardProfile from './components/TopCardProfile'
import ProfileFormCard from './components/ProfileFormCard'
import { matchGender } from 'shared/matchData/matchData'
import { findDocumentText } from 'shared/helpers/functions-helpers'
import { userUpdateMyInfo } from 'local_redux/actions/userActions'
import { USER_UPDATE_MY_INFO_RESET } from 'local_redux/constants/userConstants'
import styles from './styles/userProfileScreenStyles'

const useStyles = makeStyles(styles)

const UserProfileScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [dniFront, setDniFront] = useState('')
	const [dniBack, setDniBack] = useState('')
	const [dniPDF, setDniPDF] = useState('')
	const [userInformation, setUserInfo] = useState({})
	const [isChangeEmailModal, setIsChangeEmailModal] = useState(false)
	const [isChangeEmailInAllProfiles, setIsChangeEmailInAllProfiles] = useState(null)
	const [errorPasswordValidation, setErrorPasswordValidation] = useState('')
	const [alert, setAlert] = useState(null)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { successUpdateMyInfo, userUpdateMyInfo: userUpdated } = useSelector((state) => state.userUpdateMyInfo)

	useEffect(() => {
		if (userInfo) {
			setDniFront(findDocumentText(userInfo, 'profileDniFront'))
			setDniBack(findDocumentText(userInfo, 'profileDniBack'))
			setDniPDF(findDocumentText(userInfo, 'documentPdfFile'))
			setUserInfo({ ...userInfo, password: '' })
		}
	}, [userInfo])
	useEffect(() => {
		if (userInfo && userInfo.isDefaultPassword) {
			setAlert(
				<SweetAlert
					info
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Cambiar Contraseña!'
					onConfirm={() => setAlert(null)}
					onCancel={() => setAlert(null)}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Hola {`${userInfo?.name}`}, por cuestiones de seguridad, es necesario que cambie la contraseña asignada por
					defecto.
				</SweetAlert>,
			)
		}
	}, [userInfo])
	useEffect(() => {
		let timeOut = ''
		if (successUpdateMyInfo) {
			setErrorPasswordValidation('')
			setUserInfo({ ...userInfo, ...userUpdated, password: '', isDefaultPassword: false })
			timeOut = setTimeout(() => {
				dispatch({ type: USER_UPDATE_MY_INFO_RESET })
			}, 1000)
		}
		return () => clearTimeout(timeOut)
	}, [successUpdateMyInfo])
	useEffect(() => {
		if (successUpdateMyInfo && isChangeEmailInAllProfiles !== null) {
			if (isChangeEmailInAllProfiles === true) {
				setAlert(
					<SweetAlert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title='¡Hecho!'
						onConfirm={() => confirmSuccess()}
						confirmBtnCssClass={classes.confirmBtnCssClass}
					>
						Cambió el email en su usuario y todos sus perfiles
					</SweetAlert>,
				)
			} else {
				setAlert(
					<SweetAlert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title='¡Hecho!'
						onConfirm={() => confirmSuccess()}
						confirmBtnCssClass={classes.confirmBtnCssClass}
					>
						Cambió el email sólo en su usuario
					</SweetAlert>,
				)
			}
		}
	}, [successUpdateMyInfo, isChangeEmailInAllProfiles])

	const confirmSuccess = () => {
		setIsChangeEmailInAllProfiles(null)
		setAlert(null)
	}
	const handleEmailModal = () => {
		setIsChangeEmailModal(!isChangeEmailModal)
	}
	const handleCancelEmailChange = () => {
		setIsChangeEmailInAllProfiles(false)
		dataSender({ ...userInformation, isChangeEmail: false })
		handleEmailModal()
	}
	const handleConfirmEmailChange = () => {
		setIsChangeEmailInAllProfiles(true)
		dataSender({ ...userInformation, isChangeEmail: true })
		handleEmailModal()
	}
	const dataSender = (info) => {
		const userData = {
			_id: info._id,
			name: info.name,
			lastName: info.lastName || '',
			dni: info.dni,
			phone: info.phone,
			phone2: info.phone2,
			email: info.email,
			password: info.password,
			gender: matchGender(info.gender),
			address: info.address ?? info.address,
			isChangeEmail: info?.isChangeEmail || null,
			profileDniFront: typeof dniFront === 'object' ? dniFront[0] : '',
			profileDniFrontOldPath: typeof dniFront === 'string' ? dniFront : '',
			profileDniBack: typeof dniBack === 'object' ? dniBack[0] : '',
			profileDniBackOldPath: typeof dniBack === 'string' ? dniBack : '',
			documentPdfFile: typeof dniPDF === 'object' ? dniPDF[0] : '',
			dniPdfFileOldPath: typeof dniPDF === 'string' ? dniPDF : '',
		}

		dispatch(userUpdateMyInfo(userData))
	}

	return (
		<>
			{Object.keys(userInformation).length > 0 && (
				<GridContainer>
					<GridItem xs={12} sm={6} style={{ margin: 'auto' }}>
						<TopCardProfile userInfo={userInformation} classes={classes} />
					</GridItem>
					<GridItem xs={12} sm={11} md={10} lg={8} style={{ margin: 'auto' }}>
						<ProfileFormCard
							classes={classes}
							userInformation={userInformation}
							setUserInfo={setUserInfo}
							errorPasswordValidation={errorPasswordValidation}
							setErrorPasswordValidation={setErrorPasswordValidation}
							dniFront={dniFront}
							setDniFront={setDniFront}
							dniBack={dniBack}
							setDniBack={setDniBack}
							dniPDF={dniPDF}
							setDniPDF={setDniPDF}
							dataSender={dataSender}
						/>
					</GridItem>

					{isChangeEmailModal && (
						<EmailChangeModal
							open={isChangeEmailModal}
							handleCloseModal={handleEmailModal}
							message={
								<>
									¿Desea tambien cambiar el email de contacto en todos sus perfiles por{' '}
									<strong>{userInformation.email}</strong>?
								</>
							}
							modalTitle={'Cambio de Email'}
							handleCancel={handleCancelEmailChange}
							handleConfirm={handleConfirmEmailChange}
						/>
					)}
					{alert}
				</GridContainer>
			)}
		</>
	)
}

export default UserProfileScreen
